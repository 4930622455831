import {createTheme} from '@mui/material/styles';
import {red, teal} from '@mui/material/colors';
import {PaletteOptions} from "@mui/material";
import {TypographyOptions} from "@mui/material/styles/createTypography";

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: 'rgba(249, 187, 125, 1)', // F9BB7D fawn
    light: '#FBD6B1',
    dark: '#753D06', // chocolate
  },
  secondary: {
    main: 'rgba(254, 217, 155, 1)', // FED99B sunset
  },
  error: {
    main: red[500],
  },
  warning: {
    main: 'rgba(254, 101, 79, 1)', // tomato
  },
  info: {
    main: 'rgba(214, 239, 255, 1)', // columbia blue
  },
  success: {
    main: teal[600],
  },
  text: {
    primary: '#000000',
    secondary: '#ffffff',
  },
  background: {
    default: 'rgba(249, 187, 125, 1)',
    paper: 'rgba(249, 187, 125, 1)',
  },
  divider: '#bdbdbd',
  common: {
    black: '#000000',
    white: '#ffffff',
  },
};

const typography: TypographyOptions = {
  fontFamily: '"Nunito Sans", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
};

const rawTheme = createTheme({
  palette: palette,
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: 600,
  fontFamily: 'Georama, sans-serif',
  textTransform: 'uppercase',
};

export const theme = createTheme(rawTheme, {
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
  },
});
