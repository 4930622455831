import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {theme} from '../themes/theme';
import {Card, CardContent, CardMedia, Grid, Link, Typography} from "@mui/material";
import {ArrowOutward} from "@mui/icons-material";

const data = [
  {
    id: 1,
    title: 'Restaurant 1',
    subtitle: 'WP Corporate Plus',
    imageUrl: 'https://via.placeholder.com/300',
    href: '/restaurant-1',
  },
  {
    id: 2,
    title: 'Restaurant 2',
    subtitle: 'WP Appetizer+Razam',
    imageUrl: 'https://via.placeholder.com/300',
    href: '/restaurant-2',
  },
  {
    id: 100,
    title: 'Beauty 1',
    subtitle: 'WP Blossom Spa',
    imageUrl: 'https://via.placeholder.com/300',
    href: '/beauty-1',
  },
];

const CardGrid = () => {
  return (
    <Grid container spacing={3}>
      {data.map((card) => (
        <Grid item xs={12} sm={6} md={4} key={card.id}>
          <Card sx={{
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
              '& $overlay': {
                opacity: 1,
              },
              transform: 'scale(1.1)',
            },
            transition: 'transform 0.3s',
          }}>
            <CardMedia
              sx={{
                height: 150,
              }}
              image={card.imageUrl}
              title={card.title}
            />
            <CardContent>
              <Typography variant="h6">
                <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  href={card.href}
                >
                  {card.title} <ArrowOutward />
                </Link>
              </Typography>
              <Typography variant="subtitle1">
                {card.subtitle}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

function Home() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <CardGrid />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default Home;
