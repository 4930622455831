import React from 'react';
import {Routes} from "./layout/Routes";

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
